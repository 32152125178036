import React, { memo } from 'react';
import useDesktopDisplay from '../../hooks/useDesktopDisplay';
import PermanentDrawer from './permanent-drawer'
import TemporaryDrawer from './temporary-drawer'

function Navigation() {

    const isDesktop = useDesktopDisplay();

    return (
        isDesktop
            ? <PermanentDrawer/>
            : <TemporaryDrawer/>
    );
};

Navigation.propTypes = {};

export default memo(Navigation);