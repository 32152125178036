import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
      padding: 0,
        justifyContent: `center`,
        display: `flex`,
        marginBottom: theme.spacing(1.5),
    },
    div: {
        display: `flex`,
        flexWrap: `wrap`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        width: `100%`,

        '& > div:not(:last-child)': {
            marginBottom: theme.spacing(3),
        },


        [theme.breakpoints.up(`sm`)]: {
            flexWrap: `wrap`,
            flexDirection: `row`,
            maxWidth: `560px`,
            justifyContent: `space-between`,
            alignItems: `flex-start`,

            '& > div:not(:last-child)': {
                marginBottom: `0`,
            },
        },


    }
}));

function Comparison(props) {
    const { children } = props;
    const classes = useStyles();
    return (
        <Container className={classes.container}>
            <div className={classes.div}>
                {children}
            </div>
        </Container>
    );

}

Comparison.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(Comparison);