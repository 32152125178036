// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/matt/Projects/twenty-fifteen/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-post-single-js": () => import("/Users/matt/Projects/twenty-fifteen/src/components/post-single.js" /* webpackChunkName: "component---src-components-post-single-js" */),
  "component---src-components-index-js": () => import("/Users/matt/Projects/twenty-fifteen/src/components/index.js" /* webpackChunkName: "component---src-components-index-js" */),
  "component---src-components-category-js": () => import("/Users/matt/Projects/twenty-fifteen/src/components/category.js" /* webpackChunkName: "component---src-components-category-js" */),
  "component---src-pages-404-js": () => import("/Users/matt/Projects/twenty-fifteen/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/matt/Projects/twenty-fifteen/.cache/data.json")

