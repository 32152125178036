import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';

import MenuToggle from './menu-toggle';
import Icon from './icon';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles(theme => ({
    toolbar: {
        backgroundColor: theme.palette.common.white,
        padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    },
    span: {
        marginLeft: theme.spacing(.5)
    },
}));


function MenuCollapse() {
    const classes = useStyles();
    return (
        <Toolbar className={classes.toolbar}>
            <MenuToggle> <Icon
                path={'M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 0 0 0 13.8z'}/>
                <span className={classes.span}>隐藏</span>
            </MenuToggle>
        </Toolbar>
    );
}

export default memo(MenuCollapse);