import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import Branding from '../branding';
import Menu from '../menu'

const useStyles = makeStyles({
    drawer: {
        width: 256,
    },
    drawerPaper: {
        width: 256,
    }
});

function PermanentDrawer() {
    const classes = useStyles();
    return (
        <Drawer className={classes.drawer} classes={{ paper: classes.drawerPaper }} anchor={`left`}
                variant={`permanent`}>
            <Branding/>
            <Menu/>
        </Drawer>
    );
}

export default memo(PermanentDrawer);