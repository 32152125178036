import React, { useContext, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import MenuCollapse from '../menu-collapse'
import Menu from '../menu'
import DrawerContext from '../../contexts/drawer-context'

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        width: 275,
        backgroundColor: theme.palette.common.white,
    },
}));

function TemporaryDrawer() {
    const {open, toggleOpen} = useContext(DrawerContext);
    const classes = useStyles();
    return (
        <SwipeableDrawer
            classes={{paper: classes.drawerPaper}}
            anchor={`left`}
            open={open}
            onClose={toggleOpen}
            onOpen={toggleOpen}
        >
            <MenuCollapse/>
            <Menu/>
        </SwipeableDrawer>
    );
}

export default memo(TemporaryDrawer);