import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Navigation from './navigation'
import Main from './main';
import ActionBar from './action-bar'
import Hidden from '@material-ui/core/Hidden';

function Layout(props) {
    const { children } = props;
    return (
        <div style={{ display: `flex` }}>
            <Navigation/>
            <Main>
                {children}
            </Main>
            <Hidden mdUp>
                <ActionBar/>
            </Hidden>
        </div>)
};


Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
