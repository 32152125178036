import React, { createContext, useState } from 'react';

const defaultContextValue = {
    open: false,
    setOpen: null,
};

const DrawerContext = createContext(defaultContextValue);

function DrawerContextProvider(props) {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <DrawerContext.Provider value={{ open, toggleOpen }}>
            {props.children}
        </DrawerContext.Provider>
    );
}

export { DrawerContext as default, DrawerContextProvider };