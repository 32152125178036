import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Red from '@material-ui/core/colors/red';

import PropTypes from 'prop-types';

import Genericon from '../genericon';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    wrapper: {},
    notification: {
        width: `256px`,
        borderRadius: `4px`,
        padding: theme.spacing(.75),
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        backgroundColor: theme.palette.neutral[1200],
    },
    h1: {
        textAlign: `center`,
        margin: 0,
        padding: 0,
        fontSize: theme.typeScale[`20px`],
        lineHeight: 1.2,
        fontWeight: 600,
        marginTop: `0.4em`,
        marginBottom: `0.8em`,
    },
    main: {
        marginBottom: theme.spacing(1),
    },
    icon: {
        width: `42px`,
        height: `42px`,
        borderRadius: `50%`,
        marginBottom: theme.spacing(1),
        '&:before': {
            width: `42px`,
            height: `42px`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
        },
    },
    correct: {
        color: `rgb(19, 109, 52)`,
        background: `hsl(142, 55%, 75%)`
    },
    incorrect: {
        color: `hsla(0, 56%, 25%, 1)`,
        background: `hsl(0, 92%, 85%)`,
    },
    buttonsArea: {
        width: '100%',
        display: `flex`,
        justifyContent: `center`,
        "& span:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },
    button: {
        padding: `${theme.spacing(.5)} ${theme.spacing(1.25)}`,
        borderRadius: `4px`,
    },
    buttonPositive: {
        backgroundColor: Red[300],
        color: theme.palette.common.white,
    },
    buttonNegative: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderColor: theme.palette.border,
        color: theme.palette.neutral[300]
    }
}));

function Notification(props) {

    // debugging
    // console.log('props in Notification', this.props);

    // do some destructuring
    const { title, body, buttons, correct } = props;
    const classes = useStyles();
    const { wrapper, icon, notification, buttonsArea, button, main, incorrect, buttonNegative, buttonPositive } = classes;
    return (
        <div className={wrapper}>
            <Genericon className={`${icon} ${correct ? classes.correct : incorrect}`}
                       icon={correct ? 'checkmark' : 'close'}/>
            <Card className={notification}>
                <Typography variant={`h1`} classes={{ h1: classes.h1 }}>{title}</Typography>
                <div className={main}>{body}</div>
                {buttons && <div className={buttonsArea}>
                    <span className={`${button} ${buttonNegative}`}>{buttons.negative}</span>
                    <span className={`${button} ${buttonPositive}`}>{buttons.positive}</span>
                </div>}
            </Card>
        </div>
    );
}

Notification.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    correct: PropTypes.bool
};

export default memo(Notification);

