import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import DrawerContext from '../../contexts/drawer-context';
import useDesktopDisplay from '../../hooks/useDesktopDisplay';


const useStyles = makeStyles(theme => ({

    link: {
        color: theme.palette.neutral[200],
        textDecoration: `none`,
        padding: theme.spacing(.75, 3),
        width: `100%`,
        fontSize: theme.typeScale[`18px`],

        '&:before': {
            content: "' '",
            opacity: 0,
            transform: `scale(0.1)`,
            borderRadius: `100%`,
            marginTop: theme.spacing(.6),
            left: theme.spacing(2),
            height: `6px`,
            width: `6px`,
            transition: `all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s`,
            position: `absolute`,
            background: theme.palette.neutral[500],
        },

        "&:hover": {
            backgroundColor: `transparent`,
            '&:before': {
                opacity: 1,
                transform: `scale(1)`,
            }
        },

        [theme.breakpoints.up(`md`)]: {
            fontSize: theme.typeScale[`16px`],
            '&:before': {
                marginTop: theme.spacing(.5),
            },
        },
    },

    active: {
        color: theme.palette.primary[500],
        backgroundColor: `hsl(283, 64%, 98%)`,

        "&:hover": {
            backgroundColor: `hsl(283, 64%, 98%)`,
        },

        '&:before': {
            marginTop: theme.spacing(.6),
            content: "' '",
            opacity: 1,
            transform: `scale(1)`,
            background: theme.palette.primary[500],
        },

        [theme.breakpoints.up(`md`)]: {
            fontSize: theme.typeScale[`16px`],
            '&:before': {
                marginTop: theme.spacing(.5),
            },
        },

    }
}));


function InternalLink(props) {
    const classes = useStyles();
    const {toggleOpen} = useContext(DrawerContext);
    const desktopDisplay = useDesktopDisplay();
    return (
        <Link className={classes.link} onClick={desktopDisplay ? null : toggleOpen} activeClassName={classes.active}
              to={props.to}>
            {props.label}
        </Link>
    )
}

InternalLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default memo(InternalLink);