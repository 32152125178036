import React, {memo} from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import ListWidget from './list-widget';
import ExternalLink from './external-link';

const query = graphql`
    query blogrollQuery {
        site {
            siteMetadata {
                blogroll {
                    title
                    url
                }
            }
        }
    }
`;

function BlogRoll() {
    const { site } = useStaticQuery(query);
    return (
        <ListWidget title={`友情链接`}>
            {site.siteMetadata.blogroll.map((node) => (
                <ExternalLink key={node.title} url={node.url} label={node.title}/>
            ))}
        </ListWidget>
    )
}

export default memo(BlogRoll);