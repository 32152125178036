import { createMuiTheme } from '@material-ui/core/styles';

export const palette = {
    action: {
        selected: `hsl(283, 64%, 98%)`
    },
    background: 'hsl(0, 100%, 100%)',
    border: 'hsl(0, 0%, 89%)',
    neutral: {
        100: "hsl(0, 0%, 13%)",
        200: "hsl(0, 0%, 23%)",
        300: "hsl(0, 0%, 32%)",
        400: "hsl(0, 0%, 38%)",
        500: "hsl(0, 0%, 49%)",
        600: "hsl(0, 0%, 62%)",
        700: "hsl(0, 0%, 69%)",
        800: "hsl(0, 0%, 69%)",
        900: "hsl(0, 0%, 81%)",
        1000: "hsl(0, 0%, 88%)",
        1100: "hsl(0, 0%, 93%)",
        1200: "hsl(0, 0%, 97%)",

    },
    primary: {
        dark: "#41166b",
        main: "#5D2099",
        500: "#5D2099",
        light: "#7d4cad",
    },
};

const typeScale = {
    '12px': `12px`,
    '14px': `14px`,
    '16px': `16px`,
    '18px': `18px`,
    '20px': `20px`,
    '24px': `24px`,
    '30px': `30px`,
    '36px': `36px`,
    '48px': `48px`,
    '60px': `60px`,
    '72px': `72px`,
};

export default createMuiTheme({
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                flexGrow: 1,
                top: `auto`,
                bottom: 0,
            }
        }
    },
    typeScale,
    spacing: factor => `${factor * 16}px`,
    transition: `ease all 100ms`,
    opacity: 0.7,
    palette,
    typography: {
        useNextVariants: true,
        h1: {
            color: palette.neutral[100],
            fontSize: typeScale['36px'],
            marginBottom: 24,
            fontWeight: 500,
        },
        body1: {
            fontSize: typeScale['18px'],
            lineHeight: 1.6,
        },
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});