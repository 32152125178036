import React, { Component } from 'react';
import Layout from '../components/layout';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

import theme from '../styles/theme';
import { DrawerContextProvider } from '../contexts/drawer-context';


export default class extends Component {
    render() {
        return (
                <DrawerContextProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <Layout>
                            {this.props.children}
                        </Layout>
                    </ThemeProvider>
                </DrawerContextProvider>
        );
    }
};
