import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    div: {
        fontSize: theme.typeScale[`18px`],
        padding: theme.spacing(.5, 3),
        color: theme.palette.neutral[500],
        fontWeight: 800,
        letterSpacing: `.05em`,
        textTransform: `uppercase`,

        [theme.breakpoints.up(`md`)]: {
            fontSize: theme.typeScale[`16px`],
        },
    }
}));

function WidgetTitle(props) {
    const classes = useStyles();
    return (
        <div className={classes.div}>❖ {props.children}</div>
    );
}

WidgetTitle.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(WidgetTitle);