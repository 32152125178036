module.exports = [{
      plugin: require('/Users/matt/Projects/twenty-fifteen/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/Users/matt/Projects/twenty-fifteen/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"posts":"/Users/matt/Projects/twenty-fifteen/src/components/main.js","default":"/Users/matt/Projects/twenty-fifteen/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"maxWidth":1440,"withWebp":true,"quality":80}},{"resolve":"gatsby-plugin-categories","options":{"templatePath":"/Users/matt/Projects/twenty-fifteen/src/components/category.js"}}]},
    },{
      plugin: require('/Users/matt/Projects/twenty-fifteen/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/matt/Projects/twenty-fifteen/src/templates/index.js"},
    },{
      plugin: require('/Users/matt/Projects/twenty-fifteen/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/matt/Projects/twenty-fifteen/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
