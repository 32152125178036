import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import WidgetTitle from './widget-title';
import List from '@material-ui/core/List';

const useStyles = makeStyles(theme => ({
    aside: {
        width: `100%`,
    },

    list: {
        width: `100%`,
        padding: 0,
        display: `flex`,
        flexDirection: `column`,
        marginBottom: theme.spacing(2),
        '& > a > li:selected': {
            backgroundColor: theme.palette.primary[1000]
        },
    },
}));


function ListWidget(props) {
    const { title, children } = props;
    const classes = useStyles();
    return (
        (<aside className={classes.aside}>
            <WidgetTitle>{title}</WidgetTitle>
            <List className={classes.list} component={`ol`}>
                {children}
            </List>
        </aside>)
    );
}


ListWidget.propTypes = {
    children: PropTypes.node.isRequired,
};


export default memo(ListWidget)