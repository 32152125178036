import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import AppBar from '@material-ui/core/AppBar/index';
import Toolbar from '@material-ui/core/Toolbar/index';

import MenuOpen from './menu-open';

const useStyles = makeStyles(theme => ({
    appBar: {
        flexGrow: 1,
        top: `auto`,
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
    },
    toolBar: {
        justifyContent: `center`
    },
}));

function ActionBar() {
    const classes = useStyles();
    return (
        <AppBar component={`div`} className={classes.appBar} position={`fixed`} color={`default`}>
            <Toolbar className={classes.toolBar}>
                <MenuOpen/>
            </Toolbar>
        </AppBar>
    );
}

export default ActionBar;