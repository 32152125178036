import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

function Icon({ path, ...otherProps }) {
    return (<SvgIcon viewBox="64 64 896 896" data-icon="menu-unfold" width="1em" height="1em"
                     fill="currentColor" aria-hidden="true" focusable="false" {...otherProps}>
        <path d={path}/>
    </SvgIcon>)
}


Icon.propTypes = {
    path: PropTypes.string.isRequired
};

export default memo(Icon);