import React, {memo} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ListWidget from './list-widget';
import InternalLink from './internal-link';
import { getCategories } from '../../lib/helpers';

const query = graphql`
    query categoriesQuery {
        allMdx{
            edges {
                node {
                    frontmatter {
                        categories
                    }
                }
            }
        }
    }
`;

function Categories() {
    const { allMdx } = useStaticQuery(query);
    const categories = getCategories(allMdx.edges);

    return (
        <ListWidget title={`分类目录`}>
            {categories.map((category) => (
                <InternalLink key={category} to={`/${category}`} label={category}/>
            ))}
        </ListWidget>
    )
}

export default memo(Categories);