import React, { memo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ListWidget from './list-widget';
import InternalLink from './internal-link';

const query = graphql`
    query recentArticlesQuery {
        allMdx(limit: 6, sort: { fields: [frontmatter___date], order: DESC }){
            edges {
                node {
                    fields {
                        slug
                    }
                    id
                    frontmatter {
                        title
                    }
                }
            }
        }
    }
`;

function RecentPosts() {
    const { allMdx } = useStaticQuery(query);
    return (
        <ListWidget title={`近期文章`}>
            {allMdx.edges.map(({ node }) => (
                <InternalLink key={node.fields.slug} to={node.fields.slug} label={node.frontmatter.title}/>
            ))}
        </ListWidget>
    )
}

export default memo(RecentPosts)