// helper functions
export const normalizePathname = (pathname) => {

    if (!pathname) {
        return null;
    }

    return pathname.substr(-1) === '/'
        ? pathname.substr(0, pathname.length - 1)
        : pathname;

};

export function getCategories(edges) {
    return (
        edges.reduce((acc, { node }) => {
                node.frontmatter.categories.forEach(category => {
                    if (!acc.includes(category)) {
                        acc.push(category);
                    }
                });
                return acc;
            }
            , [])
    );
}

export const formatDate = (str) => {
    const arr = str.split('-');
    if (arr.length !== 3) {
        throw new Error(`Invalid date format in formatDate: ${str}`);
    }
    return `${arr[0]}年${arr[1]}月${arr[2]}日`;
};

export const getPaginationNeighbors = (humanPageNumber, lastPageNumber) => {

    // build an array of neighbors > 0
    let neighbors = [humanPageNumber - 1, humanPageNumber, humanPageNumber + 1].filter(num => num > 0 && num < lastPageNumber + 1);

    // add some padding to front if needed
    if (neighbors[0] > 2) {
        neighbors = ['...', ...neighbors];
    }

    // add some padding to back if needed
    if (neighbors[neighbors.length - 1] < lastPageNumber - 1) {
        neighbors = [...neighbors, '...'];
    }

    return neighbors.map((val, idx) => ({ val, key: idx }));
};