import React, { useEffect, useState } from 'react';
import { mobileBreakPoint } from '../components/constants';

export default function useDesktopDisplay() {
    const [width, setWidth] = useState(0);
    const handleResize = () => setWidth(window.innerWidth);

    useEffect(() => {

        // componentDidMount
        if (width === 0) {
            handleResize();
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    return width >= mobileBreakPoint;
}

