import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
    main: {
        flexGrow: 1,
        overflow: `auto`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
    },
    toolbarMargin: {
        ...theme.mixins.toolbar,
        width: `100%`,
    },
}));


export default function Main(props) {
    const classes = useStyles();
    return (
        <main role={`main`} className={classes.main}>
            {props.children}
            <Hidden mdUp>
                <div className={classes.toolbarMargin}/>
            </Hidden>
        </main>
    );
};

Main.propTypes = {
    children: PropTypes.node.isRequired,
};

export const postQuery = graphql`
    fragment post on Mdx {
        id
        fields {
            slug
        }
        code {
            body
        }
        frontmatter {
            title
            date
            hideTitleText
            categories
            headerImage {
                childImageSharp {
                    fluid {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
            images {
                childImageSharp {
                    fluid(maxWidth: 1280) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
    }
`;

