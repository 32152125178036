import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';

import MenuToggle from './menu-toggle';
import Icon from './icon';

const useStyles = makeStyles(theme => ({
    span: {
        color: `white`,
        marginLeft: theme.spacing(.5)
    },
}));


function MenuOpen() {
    const classes = useStyles();
    return (
        <MenuToggle> <Icon
            style={{fill: `white`}}
            path={'M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z'}/>
            <span className={classes.span}>菜单</span>
        </MenuToggle>

    );
}

export default memo(MenuOpen);