import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import List from '@material-ui/core/List/index';
import RecentPosts from '../widgets/recent-posts';
import Categories from '../widgets/categories';
import Blogroll from '../widgets/blogroll';


const useStyles = makeStyles(theme => ({
    div: {
      backgroundColor: theme.palette.common.white,
    },
    list: {
        padding: 0,
        width: `100%`,
        flexShrink: 0,
    },
}));

function Menu() {
    const classes = useStyles();

    return (
        <div className={classes.div}>
            <List role={`presentation`} className={classes.list} component={`nav`}>
                <RecentPosts/>
                <Categories/>
                <Blogroll/>
            </List>
        </div>
    )
}

export default memo(Menu)