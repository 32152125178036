import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import DrawerContext from '../contexts/drawer-context';

import Icon from './icon';

const useStyles = makeStyles(theme => ({
    button: {
        fontSize: theme.typeScale[`18px`],
        padding: theme.spacing(.375, 1),
            [theme.breakpoints.up(`sm`)]: {
            padding: theme.spacing(.375, .5)
        }
    },
}));


export default function MenuToggle(props) {
    const classes = useStyles();
    const { toggleOpen } = useContext(DrawerContext);
    // console.log('open in MenuToggle', open);

    return (
        <Button href={``} aria-label={`菜单`} className={classes.button} onClick={toggleOpen}>
            {props.children}
        </Button>
    );
}