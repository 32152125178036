import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import '../styles/scss/abstracts/genericons.css';

const useStyles = makeStyles(theme => ({
    icon: {
        lineHeight: 0,
        '&:before': {
            color: `inherit`,
            opacity: `inherit`,
            display: `inline-block`,
            fontSize: theme.typeScale[`24px`],
            speak: `none`,
            fontFamily: `Genericons`,
            fontStyle: `normal`,
            fontWeight: `normal`,
            lineHeight: 1,
            textAlign: `center`,
            textTransform: `none`,
            textDecoration: `inheret`,
        },
    }
}));

function Genericon(props) {
    const { icon, className } = props;
    const classes = useStyles();
    return (
        <i className={`genericon genericon-${icon} ${classes.icon} ${className}`}/>
    );
}

Genericon.propTypes = {
    icon: PropTypes.string.isRequired
};

export default memo(Genericon);