import React, { memo } from 'react';

import { makeStyles } from '@material-ui/styles';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';

Branding.propTypes = {};

const useStyles = makeStyles(theme => ({
    div: {
        padding: theme.spacing(4, 3, 3, 3)
    },
    link: {
        textDecoration: `none`,
        color: theme.palette.neutral[200],

    },
    h1: {
        marginBottom: theme.spacing(.5),
        opacity: 1,
        transition: `opacity 200ms ease-in`,
        color: theme.palette.neutral[200],
        "&:hover": {
            opacity: .66,
        },
    },
    h4: {
        fontSize: theme.typeScale[`16px`]
    }
}));

function Branding() {
    const { site } = useStaticQuery(graphql`
        query HeaderQuery {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `);
    const { siteMetadata } = site;
    const classes = useStyles();
    return (
        <div className={classes.div}>
            <Link className={classes.link} to={`/`}>
                <Typography classes={{ h1: classes.h1 }} variant="h1">
                    {siteMetadata.title}
                </Typography>
                <Typography classes={{ h4: classes.h4 }} variant="h4">
                    {siteMetadata.description}
                </Typography>
            </Link>
        </div>
    );
}

export default memo(Branding)
