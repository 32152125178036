import React, {memo} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.neutral[200],
        textDecoration: `none`,
        padding: theme.spacing(.75, 3),
        width: `100%`,
        fontSize: theme.typeScale[`18px`],

        '&:before': {
            content: "' '",
            opacity: 0,
            transform: `scale(0.1)`,
            borderRadius: `100%`,
            marginTop: theme.spacing(.6),
            left: theme.spacing(2),
            height: `6px`,
            width: `6px`,
            transition: `all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s`,
            position: `absolute`,
            background: theme.palette.neutral[500],
        },

        "&:hover": {
            backgroundColor: `transparent`,
            '&:before': {
                opacity: 1,
                transform: `scale(1)`,
            }
        },

        [theme.breakpoints.up(`md`)]: {
            fontSize: theme.typeScale[`16px`],
            '&:before': {
                marginTop: theme.spacing(.5), //revisit
            },
        },
    },
}));


function ExternalLink(props) {
    const classes = useStyles();

    return (
        <a className={classes.link} href={props.url} target={`_blank`} referrerPolicy={`no-referrer`}>
            {props.label}
        </a>
    )
}

ExternalLink.propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default memo(ExternalLink);